import React, { useContext, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { RequestService } from '../../services/RequestService'
import { AuthContext } from '../../reducers/AuthReducer'
import { INTERPRETER_STATE } from '../../constants/defaultConstants'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { AuthService as auth } from '../../services/AuthService'

export default function () {
    const classes = useStyles()
    const [state, dispatch] = useContext(AuthContext)

    const [login, setLogin] = useState(
        process.env.REACT_APP_INTERPRETER_LOGIN || ''
    )
    const [password, setPassword] = useState(
        process.env.REACT_APP_INTERPRETER_PASSWORD || ''
    )
    const [rememberMe, setRememberMe] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})

    useEffect(() => {
        const signIn = async () => {
            try {
                const authConst = await auth.signIn(
                    {
                        login,
                        password
                    },
                    (user) => {
                        console.log('@@@ user is', user)
                        if (user.type !== 'interpreter') {
                            return
                        }
                        dispatch({
                            type: INTERPRETER_STATE.SIGN_IN,
                            payload: {
                                rememberMe,
                                user: {
                                    token: user.token,
                                    email: login,
                                    id: user.id
                                }
                            }
                        })
                        setLoading(false)
                        // window.location.reload()
                    }
                )
            } catch (error) {
                setLoading(false)
                console.log('ERROR set', error.message)
                setError(error)
            }
        }

        if (loading) {
            signIn()
        }
        return () => RequestService({}).abortController.abort()
        // eslint-disable-next-line
    }, [loading])

    useEffect(() => {
        setError({})
    }, [login, password])

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
    }

    return (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
                error={!!error?.message?.length}
                helperText={error?.message}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Login"
                name="email"
                autoComplete="email"
                autoFocus
                value={login || ''}
                onChange={(e) => setLogin(e.target.value.trim())}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password || ''}
                onChange={(e) => setPassword(e.target.value.trim())}
            />

            <Button
                disabled={password.length < 8 || !login.length}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
            >
                {!loading ? (
                    'Login'
                ) : (
                    <CircularProgress size={24} color={'inherit'} />
                )}
            </Button>
        </form>
    )
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontSize: 14
    }
}))
